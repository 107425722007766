import { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

const c = <S extends string | number>(s: S) => s;

export const DefaultTheme = {
  primaryColor: c('#728263'),
  textColorContrast: c('#fff'),
  textSizePx: c(20),
};

export const DefaultThemeProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeProvider theme={DefaultTheme}>{children}</ThemeProvider>
);

import { FC, CSSProperties, useState, useEffect } from 'react';
import {
  PaymentMethod,
  CreditCardFields,
  Installment,
  BuyerFields,
  IdDocument,
  BoletoFields,
} from '../../types';
import CreditCardStep1 from './creditCardStep1';
import CreditCardStep2 from './creditCardStep2';
import BoletoStep from './boletoStep';
import ChoosePaymentMethod from './choosePaymentMethod';
import Header from './header';
import { Loader } from '../../components/Loader';
import { FormButton } from '../../components/FormButton';
import { CheckoutForm } from '../../components/CheckoutForm';
import { AmountStep } from './amountStep';
import { PixStep } from './pixStep';

const mainButtonStyle: CSSProperties = {
  marginTop: '30px',
  width: 'calc(100% + 60px)',
  marginLeft: '-30px',
  padding: '25px 25px',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  textTransform: 'uppercase',
};

interface Props {
  cardIssuer?: string;
  loading: boolean;
  documentTypes?: IdDocument[];
  installmentOptions?: Installment[];
  selectedPaymentMethod?: PaymentMethod;
  selectPaymentMethod: (method?: PaymentMethod) => void;
  handleChange(key: CreditCardFields | BuyerFields | BoletoFields, value: string): void;
  formData: {
    [Key in CreditCardFields | BuyerFields | BoletoFields]: {
      value: string;
      isValid: boolean;
    };
  };
  checkout(): void;
  goBack(): unknown;
}

const methodToTitle = (method: PaymentMethod) => {
  switch (method) {
    case 'boleto':
      return 'Boleto';
    case 'credit':
      return 'Cartão de crédito';
    case 'pix':
      return 'PIX ou depósito';
  }
};

const PaymentForm: FC<Props> = (props) => {
  const {
    loading,
    selectedPaymentMethod,
    handleChange,
    installmentOptions,
    documentTypes,
    formData,
    checkout,
    goBack,
    cardIssuer,
  } = props;
  const [creditCardStep, setCreditCardStep] = useState<0 | 1 | 2>(0);
  const [boletoStep, setBoletoStep] = useState<0 | 1>(0);

  useEffect(() => {
    setCreditCardStep(0);
    setBoletoStep(0);
  }, [selectedPaymentMethod]);

  const paddingBottom = loading || !selectedPaymentMethod ? '' : '0';

  const getNextStep = () => {
    if (selectedPaymentMethod === 'credit') {
      switch (creditCardStep) {
        case 0:
          setCreditCardStep(1);
          break;
        case 1:
          setCreditCardStep(2);
          break;
        case 2:
          checkout();
          break;
      }
    } else if (selectedPaymentMethod === 'boleto') {
      switch (boletoStep) {
        case 0:
          setBoletoStep(1);
          break;
        case 1:
          checkout();
          break;
      }
    }
  };

  const canAdvanceCredit =
    (creditCardStep === 0 &&
      formData.amount.isValid &&
      Number(formData.amount.value) > 0) ||
    (creditCardStep === 1 &&
      formData.cardNumber.isValid &&
      formData.securityCode.isValid &&
      formData.holderName.isValid &&
      formData.expiryDate.isValid) ||
    (creditCardStep === 2 &&
      formData.email.isValid &&
      formData.documentType.isValid &&
      formData.documentNumber.isValid);

  const canAdvanceBoleto =
    (boletoStep === 0 && formData.amount.isValid && Number(formData.amount.value) > 10) ||
    (boletoStep === 1 &&
      formData.firstName.isValid &&
      formData.lastName.isValid &&
      formData.email.isValid &&
      formData.documentType.isValid &&
      formData.documentNumber.isValid);

  if (!loading && !selectedPaymentMethod)
    return <ChoosePaymentMethod selectMethod={props.selectPaymentMethod} />;

  return (
    <>
      <CheckoutForm
        onSubmit={(ev) => {
          ev.preventDefault();
          getNextStep();
        }}
        noValidate={true}
        style={{ paddingBottom }}
      >
        {loading && (
          <div style={{ textAlign: 'center' }}>
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            {!!selectedPaymentMethod && (
              <Header title={methodToTitle(selectedPaymentMethod)} goBack={goBack} />
            )}
            {selectedPaymentMethod === 'boleto' && (
              <>
                {boletoStep === 0 && (
                  <>
                    <AmountStep {...formData} handleChange={handleChange} />
                    <FormButton
                      disabled={!canAdvanceCredit}
                      style={mainButtonStyle}
                      type="submit"
                    >
                      Continuar
                    </FormButton>
                  </>
                )}
                {boletoStep === 1 && (
                  <>
                    <BoletoStep
                      {...formData}
                      documentTypes={documentTypes ?? []}
                      handleChange={handleChange}
                    />
                    <FormButton
                      disabled={!canAdvanceBoleto}
                      style={mainButtonStyle}
                      type="submit"
                    >
                      Gerar boleto 🎁
                    </FormButton>
                  </>
                )}
              </>
            )}
            {selectedPaymentMethod === 'credit' && (
              <>
                {creditCardStep === 0 && (
                  <>
                    <AmountStep {...formData} handleChange={handleChange} />
                    <FormButton
                      disabled={!canAdvanceCredit}
                      style={mainButtonStyle}
                      type="submit"
                    >
                      Continuar
                    </FormButton>
                  </>
                )}
                {creditCardStep === 1 && (
                  <>
                    <CreditCardStep1
                      {...formData}
                      installmentOptions={installmentOptions}
                      handleChange={handleChange}
                      cardNumberLength={cardIssuer === 'amex' ? 15 : 16}
                      cvcLength={cardIssuer === 'amex' ? 4 : 3}
                    />
                    <FormButton
                      disabled={!canAdvanceCredit}
                      style={mainButtonStyle}
                      type="submit"
                    >
                      Continuar
                    </FormButton>
                  </>
                )}
                {creditCardStep === 2 && (
                  <>
                    <CreditCardStep2
                      {...formData}
                      documentTypes={documentTypes ?? []}
                      handleChange={handleChange}
                    />
                    <FormButton
                      disabled={!canAdvanceCredit}
                      style={mainButtonStyle}
                      type="submit"
                    >
                      Enviar presente 🎁
                    </FormButton>
                  </>
                )}
              </>
            )}
            {selectedPaymentMethod === 'pix' && <PixStep />}
          </>
        )}
      </CheckoutForm>
    </>
  );
};

export default PaymentForm;

import { FC } from 'react';
import { Button } from '../../components/Button';
import { Main } from '../rsvp/styles';

export const ThankyouPage: FC = () => {
  const { boletoUrl, name } = (window as any);
  return (
    <>
      <Main>
        <h2>🎉 Muito obrigado{name ? `, ${name}` : ''}!</h2>
        <p>
          Agradecemos de coração por abençoar a nossa vida! Você faz parte da nossa história.
        </p>
        <p>A gente se vê no grande dia! ❤️😊</p>
        {boletoUrl && (
          <Button as="a" href={boletoUrl} target="_blank">Imprimir boleto</Button>
        )}
      </Main>
    </>
  );
};

import { FC } from 'react';
import { BuyerFields, IdDocument } from '../../types';
import FormField from '../../components/FormField';
import { FormLabel } from '../../components/FormLabel';
import FormSelect from '../../components/FormSelect';

interface Props {
  handleChange(key: BuyerFields, value: string): void;
  documentTypes: IdDocument[];
}

type FieldValues = {
  [Key in BuyerFields]: { value: string; isValid: boolean };
};

const CreditCardStep2: FC<FieldValues & Props> = (props) => {
  const { handleChange, documentTypes } = props;
  return (
    <>
      <FormLabel style={{ marginTop: '40px' }}>
        E-mail
        <FormField
          autoFocus={true}
          autoComplete="email"
          value={props.email.value}
          onChange={(ev) =>
            handleChange(BuyerFields.email, ev.currentTarget.value.trim())
          }
          showValidity="afterblur"
          isValid={props.email.isValid}
          placeholder="email@exemplo.com"
        />
      </FormLabel>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginTop: '40px',
        }}
      >
        <FormLabel
          style={{
            flexGrow: 0,
            width: '80px',
          }}
        >
          Documento
          <FormSelect
            value={props.documentType.value}
            onChange={(ev) =>
              handleChange(BuyerFields.documentType, ev.currentTarget.value)
            }
            options={documentTypes.map((type) => ({
              name: type.name,
              value: type.id,
            }))}
          />
        </FormLabel>
        <FormLabel style={{ flex: 1, marginLeft: '20px' }}>
          Número
          <FormField
            value={props.documentNumber.value}
            onChange={(ev) => {
              let newValue = ev.currentTarget.value;
              if (
                props.documentTypes.find((a) => a.id === props.documentType.value)
                  ?.type === 'number'
              ) {
                newValue = newValue.match(/[0-9]+/gi)?.join('') ?? '';
              }
              handleChange(BuyerFields.documentNumber, newValue);
            }}
            showValidity="afterblur"
            isValid={props.documentNumber.isValid}
            placeholder="12345678910"
          />
        </FormLabel>
      </div>
    </>
  );
};

export default CreditCardStep2;

import styled, { css } from 'styled-components';

export const FormLabel = styled.label`
  ${({ theme }) => css`
    display: block;
    font-weight: bold;
    font-size: 18px;

    input,
    select {
      display: block;
      font-size: 18px;
      font-weight: normal;
      margin-top: 5px;
      width: 100%;
      height: 40px;
      padding: 0 7px;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid #bbb;
      transition: border-color 0.4s;
      &:focus {
        border-color: ${theme.primaryColor};
      }
    }
  `}
`;

import { FC, DetailedHTMLProps, InputHTMLAttributes } from 'react';

interface Props {
  options?: {
    name: string;
    value: string;
    disabled?: boolean;
  }[];
}

type ExtendedProps = Props &
  DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

const FormSelect: FC<ExtendedProps> = (props) => {
  const { options, ...defaultProps } = props;
  return (
    <select {...defaultProps}>
      {options?.map(({ value, name, disabled }) => (
        <option key={value} value={value} disabled={disabled}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default FormSelect;

import { FC } from 'react';
import styled, { css } from 'styled-components';
import PixQR from '../../assets/img/pixqr.png';
import { copyToClipboard } from '../../util/clipboard';

const CopyIcon = (
  <svg height="20px" viewBox="0 0 330 331" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M299 31H105V225H299V31ZM74 0V256H330V0H74Z" />
    <path fillRule="evenodd" d="M31.5 299.5V59H0.5V330.5H272V299.5H31.5Z" />
  </svg>
);

const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 0 20px;

    dt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 35px 0 10px;
      font-size: 18px;

      button {
        background: none;
        color: ${theme.primaryColor};
        border: none;
        line-height: 25px;
        position: relative;
        font-size: 16px;
        cursor: pointer;

        svg {
          vertical-align: middle;
          padding-right: 10px;
          fill: ${theme.primaryColor};
        }
      }
    }

    dd {
      font-weight: bold;
    }

    img {
      width: 100%;
    }
  `}
`;

const button = (
  <button
    onClick={(ev) => {
      const el = ev.currentTarget;
      const dd = el.parentElement?.nextElementSibling;

      if (dd) copyToClipboard(dd.innerHTML);

      el.innerHTML = el.innerHTML.replace('copiar', 'copiado!');
      setTimeout(() => {
        el.innerHTML = el.innerHTML.replace('copiado!', 'copiar');
      }, 2000);
    }}
  >
    {CopyIcon} copiar
  </button>
);

export const PixStep: FC = () => {
  return (
    <Container>
      <dl>
        <dt>Chave PIX {button}</dt>
        <dd>eldadeandre@gmail.com</dd>
        <dt>Nome {button}</dt>
        <dd>Eldade André Moreira Marcelino</dd>
        <dt>CPF {button}</dt>
        <dd>07775925922</dd>
        <dt>Banco {button}</dt>
        <dd>237</dd>
        <dt>Agência {button}</dt>
        <dd>3925</dd>
        <dt>Conta corrente {button}</dt>
        <dd>500043-2</dd>
        <dt>QR Code</dt>
        <dd>
          <img src={PixQR} alt="QR Code do PIX" />
        </dd>
      </dl>
    </Container>
  );
};

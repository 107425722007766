import { Button } from '../../components/Button';
import { Main } from './styles';

const whatsAppMessage = encodeURIComponent(
  `Olá! Gostaria de confirmar presença no casamento da Karla e do Eldade 😁`,
);

export const RsvpPage = () => {
  return (
    <Main>
      <h2>Olá!</h2>
      <p>
        Está chegando o dia de celebrar nossa união e ficaremos muito felizes de
        compartilhar esse momento com você!
      </p>
      <p>
        Pedimos que confira atentamente as informações de localização, data e horário e só
        confirme presença o quanto antes.
      </p>
      <section>
        <h3>Localização</h3>
        <div className="location">
          <div className="origin">Curitiba</div>
          <div className="path">
            43 km
            <br />
            55 min
          </div>
          <div className="destination">
            <span>Chácara Carpe Diem</span>
            <i>
              Campo Largo da Roseira
              <br />
              São José dos Pinhais
            </i>
          </div>
        </div>
        <Button as="a" href="https://goo.gl/maps/cF3iFCwtTnSiRqph7" target="_blank">
          ver no mapa
        </Button>
      </section>
      <section>
        <h3>Data e horário</h3>
        <p>Data: 11 de março de 2023</p>
        <p>Horário: 16h</p>
        <Button
          as="a"
          href="/Casamento Karla e Eldade.ics"
          download="Casamento Karla e Eldade.ics"
        >
          adicionar ao calendário
        </Button>
      </section>
      <section>
        <h3>Podemos contar com você?</h3>
        <p>Eba! Use o botão abaixo ou envie uma mensagem para nossa cerimonialista:</p>
        <p>
          <b>Anne dal Ponte</b>
          <br />
          (41) 9 9933 2337
        </p>
        <Button
          as="a"
          href={`https://wa.me/5541999332337?text=${whatsAppMessage}`}
          target="_blank"
        >
          confirmar presença
        </Button>
      </section>
      <section>
        <h3>🎁 Lista de presentes</h3>
        <p>Clique no botão abaixo para mais informações sobre os presentes.</p>
        <Button
          as="a"
          href={`/#presentes`}
        >
          ir para presentes
        </Button>
      </section>
      <section>
        <h3>Não vai poder ir?</h3>
        <p>Poxa, sentiremos sua falta 😕</p>
        <p>Pedimos a gentileza de avisar o quanto antes, pois isso nos ajudará bastante na organização.</p>
      </section>
    </Main>
  );
};

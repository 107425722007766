import axios from 'axios';
import Gateway from '..';
import { IdDocument, CreditCardFields, BuyerFields } from '../../../types';
import config from './config';

type MercadopagoDocType = {
  id: string;
  name: string;
  type: 'number' | 'text';
  min_length: string;
  max_length: string;
};

interface IMercadopago {
  setPublishableKey(key: string): void;
  getIdentificationTypes(
    cb: (status: number, idTypes: MercadopagoDocType[]) => void,
  ): void;
  getInstallments(
    _: { bin: string; amount: number },
    cb: (
      status: number,
      response: {
        payment_method_id: string;
        payment_type_id: string;
        issuer: {
          id: string;
          name: string;
          secure_thumbnail: string;
        };
        payer_costs: {
          installments: number;
          recommended_message: string;
          installment_rate: number;
        }[];
      }[],
    ) => void,
  ): void;
  createToken(
    data: HTMLFormElement,
    cb: (
      status: number,
      response: {
        id: string;
      },
    ) => void,
  ): void;
}

const w: { Mercadopago?: IMercadopago } & Window = window;

const loadLib = () =>
  new Promise<void>((resolve) => {
    if (!w.Mercadopago) {
      const s = document.createElement('script');
      s.src = 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js';
      s.onload = () => resolve();
      document.body.append(s);
      return;
    }
    resolve();
  });

const makeFakeForm = (data: { [Key: string]: string }): HTMLFormElement => {
  const form = document.createElement('form');
  document.body.append(form);
  Object.entries(data).forEach(([key, value]) => {
    const input = document.createElement('input');
    input.dataset.checkout = key;
    input.type = 'hidden';
    input.value = value;
    input.name = key;
    input.id = key;
    form.append(input);
  });
  setTimeout(() => form.remove(), 100);
  return form;
};

const fetchIdTypes = () =>
  new Promise<IdDocument[]>((resolve, reject) => {
    w.Mercadopago?.getIdentificationTypes((status, types) => {
      if (200 !== status) {
        reject();
        return;
      }
      resolve(
        types.map((docType) => ({
          id: docType.id,
          name: docType.name,
          type: docType.type,
        })),
      );
    });
  });

const MercadoPagoGateway: () => Gateway = () => {
  let idTypes: IdDocument[] | undefined;
  return {
    setup: async () => {
      await loadLib();
      w.Mercadopago!.setPublishableKey(config.publicKey);
      idTypes = await fetchIdTypes();
    },
    getIdentificationTypes: () => idTypes,
    getCardDetails: (number, amount) =>
      new Promise((resolve, reject) => {
        w.Mercadopago?.getInstallments(
          {
            bin: number.substr(0, 6),
            amount,
          },
          (status, [response]) => {
            if (status !== 200 || !response) {
              reject();
              return;
            }
            resolve({
              issuerId: response.issuer.id,
              issuerLogoUrl: response.issuer.secure_thumbnail,
              issuerName: response.issuer.name,
              methodId: response.payment_method_id,
              typeId: response.payment_type_id,
              installments: response.payer_costs.map((installment) => ({
                installments: installment.installments,
                description: installment.recommended_message,
                installmentAmount:
                  (amount + installment.installment_rate) / installment.installments,
                noInterest: installment.installment_rate === 0,
              })),
            });
          },
        );
      }),
    validateField: (key, value) => {
      switch (key) {
        case CreditCardFields.cardNumber:
          return value.length === 16 || value.length === 15;
        case CreditCardFields.securityCode:
          return value.length === 3 || value.length === 4;
        case CreditCardFields.holderName:
          return !!value;
        case CreditCardFields.expiryDate:
          const [month, year] = value.split('/').map(Number);
          return month <= 12 && month > 0 && !!year && year >= new Date().getFullYear();
        case BuyerFields.documentNumber:
          switch (value.length) {
            case 11:
              const sum1 =
                (10 *
                  value
                    .substr(0, 9)
                    .split('')
                    .reduce(
                      (prev, current, index) => prev + Number(current) * (10 - index),
                      0,
                    )) %
                11;
              const sum2 =
                (10 *
                  value
                    .substr(0, 10)
                    .split('')
                    .reduce(
                      (prev, current, index) => prev + Number(current) * (11 - index),
                      0,
                    )) %
                11;
              return (
                !/1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11}/gi.test(value) &&
                (sum1 === Number(value[9]) || (sum1 === 10 && value[9] === '0')) &&
                (sum2 === Number(value[10]) || (sum2 === 10 && value[10] === '0'))
              );
            case 13:
              return true;
            default:
              return false;
          }
        case BuyerFields.email:
          return value.match(/@/gi)?.length === 1;
        default:
          return true;
      }
    },
    checkout: (data) =>
      new Promise<string | undefined>(async (resolve, reject) => {
        if (data.checkoutType === 'credit') {
          w.Mercadopago?.createToken(
            makeFakeForm({
              amount: data.amount,
              cardExpirationMonth: data.expiryDate.split('/')[0],
              cardExpirationYear: data.expiryDate.split('/')[1].substr(2, 2),
              cardNumber: data.cardNumber,
              cardholderName: data.holderName,
              docNumber: data.documentNumber,
              docType: data.documentType,
              email: data.email,
              installments: data.installments,
              paymentMethodId: data.paymentMethodId,
              securityCode: data.securityCode,
            }),
            async (status, { id: token }) => {
              if (status !== 200 && status !== 201) {
                reject();
                return;
              }
              try {
                await axios.post(`${config.endpointBaseUrl}/mercadopagoCheckout`, {
                  amount: data.amount,
                  token,
                  installments: data.installments,
                  email: data.email,
                  methodId: data.paymentMethodId,
                  documentType: data.documentType,
                  documentNumber: data.documentNumber,
                  message: data.message,
                });
                resolve(undefined);
              } catch (err) {
                reject(err);
              }
            },
          );
        } else {
          try {
            const { data: { boletoUrl } } = await axios.post<{ boletoUrl: string }>(`${config.endpointBaseUrl}/mercadopagoCheckout`, {
              amount: data.amount,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              documentType: data.documentType,
              documentNumber: data.documentNumber,
              message: data.message,
            });
            resolve(boletoUrl);
          } catch (err) {
            reject(err);
          }
        }
      }),
  };
};

export default MercadoPagoGateway;

import { FC } from 'react';
import styled, { css } from 'styled-components';

const ProductContainer = styled.div`
  ${({ theme }) => css`
    color: #fff;
    background: ${theme.primaryColor};
    font-size: 18px;
    margin: -50px -20px 30px -20px;
    padding: 15px 20px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
  `}
`;

const BackButton = styled.button`
  top: 0;
  left: 0;
  position: absolute;
  white-space: nowrap;
  width: 48px;
  height: 48px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  background: none;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &::before {
    content: '<';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 48px;
    color: rgba(255, 255, 255, 0.8);
    width: 100%;
    text-align: center;
    font-size: 23px;
    font-weight: 400;
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0;
    top: 10%;
    height: 80%;
    width: 1px;
    background: rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const Header: FC<{
  title: string;
  goBack?: () => unknown;
}> = ({ title, goBack }) => {
  return (
    <ProductContainer>
      <BackButton onClick={goBack} type="button">
        voltar
      </BackButton>
      <p>{title}</p>
    </ProductContainer>
  );
};

export default Header;

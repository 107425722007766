import styled, { css } from 'styled-components';
import { Button } from '../../components/Button';

export const Main = styled.main`
  ${({ theme }) => css`
    padding: 0 20px;

    h2 {
      font-weight: 700;
      font-size: 35px;
    }

    h3 {
      font-weight: 700;
      font-size: 25px;
    }

    section {
      margin-top: 60px;
    }

    p {
      margin-top: 20px;
      line-height: 1.5;
    }

    u {
      text-decoration: underline;
    }

    ${Button} {
      display: block;
      width: max-content;
      margin: 30px auto 0;
    }

    b {
      font-weight: 700;
    }

    .location {
      margin-top: 30px;
      display: inline-block;

      .origin,
      .destination {
        font-size: 25px;
        margin-left: 2.3em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &::before {
          content: '';
          display: inline-block;
          width: 0.8em;
          height: 0.8em;
          background: #555;
          margin-right: 10px;
          border-radius: 1em;
          margin-top: 0.1em;
        }
      }

      .destination::before {
        background: ${theme.primaryColor};
        border-radius: 0;
      }

      .path {
        display: inline-block;
        padding-right: 17px;
        border-right: 2px solid;
        margin: 3px 0 4px;
        font-size: 16px;
      }

      span {
        flex: 1;
      }

      i {
        display: block;
        font-size: 16px;
        line-height: 1.5;
        margin: 3px 0 0 30px;
        width: 100%;
        flex-shrink: 0;
      }
    }
  `}
`;

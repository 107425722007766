import styled from 'styled-components';

export const CheckoutForm = styled.form`
  overflow: hidden;
  width: 560px;
  max-width: calc(100vw - 40px);
  margin: 30px auto;
  background: #fff;
  padding: 50px 20px;
  border-radius: 4px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
`;

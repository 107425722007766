import { FC, ReactNode } from 'react';
import { Logo } from '../Logo';
import { Nav } from '../AppNav';
import { Wrapper } from '../Wrapper';
import { Header } from './styles';

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Wrapper>
        <Header>
          <Logo />
          <div className="dates">11.03.2023 - 16h</div>
        </Header>
      </Wrapper>
      <Nav />
      <Wrapper className="mt">{children}</Wrapper>
    </>
  );
};

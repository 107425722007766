export type PaymentMethod = 'boleto' | 'credit' | 'pix';

export enum CreditCardFields {
  cardNumber = 'cardNumber',
  securityCode = 'securityCode',
  holderName = 'holderName',
  expiryDate = 'expiryDate',
  installments = 'installments',
  amount = 'amount',
  message = 'message',
}

export enum BuyerFields {
  email = 'email',
  documentType = 'documentType',
  documentNumber = 'documentNumber',
}

export enum BoletoFields {
  firstName = 'firstName',
  lastName = 'lastName',
  amount = 'amount',
  message = 'message',
}

export type Installment = {
  installments: number;
  description: string;
  installmentAmount: number;
  noInterest: boolean;
};

export interface IdDocument {
  id: string;
  name: string;
  type: 'number' | 'text';
}

import styled, { css } from 'styled-components';

export const FormButton = styled.button`
  ${({ theme }) => css`
    font-size: 16px;
    color: #fff;
    background: ${theme.primaryColor};
    padding: 15px 25px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
      opacity: 0.9;
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.8;
    }
  `}
`;

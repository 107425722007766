import { FC } from 'react';
import { Layout } from './components/AppLayout';
import { GiftsPage } from './pages/gifts';
import { RsvpPage } from './pages/rsvp';
import { ThankyouPage } from './pages/thankyou';
import { Pages, useRouter } from './router';
import { GlobalStyle } from './styles';
import { DefaultThemeProvider } from './styles/theme';

const App: FC = () => {
  const { page } = useRouter();

  return (
    <DefaultThemeProvider>
      <GlobalStyle />
      <Layout>
        {page === Pages.RSVP && <RsvpPage />}
        {page === Pages.GIFTS && <GiftsPage />}
        {page === Pages.THANK_YOU && <ThankyouPage />}
      </Layout>
    </DefaultThemeProvider>
  );
};

export default App;

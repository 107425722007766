import styled from 'styled-components';

const LoaderIcon = styled.svg`
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  animation: spin 0.6s linear 0s infinite;
`;

export const Loader = () => (
  <LoaderIcon width="64" height="64" viewBox="0 0 410 410">
    <path
      fill="#777"
      d="M205 410C91.7816 410 0 318.218 0 205C0 91.7816 91.7816 0 205 0C318.218 0 410 91.7816 410 205H379C379 108.902 301.098 31 205 31C108.902 31 31 108.902 31 205C31 301.098 108.902 379 205 379V410Z"
    />
  </LoaderIcon>
);

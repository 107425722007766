import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
    outline: none;
    line-height: inherit;
    color: inherit;
    text-decoration: none;
    font-style: normal;
    font-weight: inherit;
  }

  body {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    background: #fff;
    line-height: 1;
    color: #444;
    font-size: ${({ theme }) => theme.textSizePx}px;
    padding-bottom: 50px;
  }
`;

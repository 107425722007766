import styled, { css } from 'styled-components';

export const Button = styled.button`
  ${({ theme }) => css`
    font-weight: 700;
    padding: 14.5px 25px;
    border: 3px solid ${theme.primaryColor};
    display: inline-block;
    color: ${theme.primaryColor};
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 12px;
    max-width: 100%;
    text-align: center;
    line-height: 1.5;
    font-size: 18px;
  `}
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;

  &.mt {
    margin-top: 50px;
  }
`;

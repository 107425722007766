import { FC, useEffect, useRef } from 'react';
import FormField from '../../components/FormField';
import { FormLabel } from '../../components/FormLabel';
import FormSelect from '../../components/FormSelect';
import { CreditCardFields, Installment } from '../../types';

interface Props {
  handleChange(key: CreditCardFields, value: string): void;
  installmentOptions?: Installment[];
  cvcLength: number;
  cardNumberLength: number;
}

type FieldValues = {
  [Key in CreditCardFields]: { value: string; isValid: boolean };
};

const formatCardNumber = (value: string) =>
  value.match(/([0-9]{1,4})/gi)?.join(' ') ?? '';

const CreditCardStep1: FC<Props & FieldValues> = (props) => {
  const { handleChange, installmentOptions, cvcLength, cardNumberLength } = props;
  const cardNumberField = useRef<HTMLInputElement>(null);
  const cvcField = useRef<HTMLInputElement>(null);
  const holderField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    cardNumberField.current?.focus();
  }, []);

  const handleCardNumberChange = (value: string) => {
    const newValue =
      value
        .trim()
        .match(/[0-9]+/gi)
        ?.join('')
        .substr(0, 16) ?? '';

    handleChange(CreditCardFields.cardNumber, newValue);

    return newValue;
  };

  return (
    <>
      <FormLabel style={{ flex: 1 }}>
        Número do cartão
        <FormField
          showValidity="afterblur"
          ref={cardNumberField}
          isValid={props.cardNumber.isValid}
          onBlur={(ev) => handleCardNumberChange(ev.currentTarget.value)}
          onChange={(ev) => {
            if (
              handleCardNumberChange(ev.currentTarget.value).length === cardNumberLength
            )
              cvcField.current?.focus();
          }}
          autoComplete="cc-number"
          pattern="[0-9]*"
          inputMode="numeric"
          value={formatCardNumber(props.cardNumber.value)}
          placeholder="Somente números"
          interactionLocked={true}
        />
      </FormLabel>
      <FormLabel style={{ marginTop: '40px' }}>
        CVC
        <FormField
          showValidity="afterblur"
          ref={cvcField}
          isValid={props.securityCode.isValid}
          placeholder={cvcLength === 4 ? '1234' : '123'}
          autoComplete="cc-csc"
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={(ev) => {
            const newValue =
              ev.currentTarget.value
                .trim()
                .match(/[0-9]+/gi)
                ?.join('')
                .substr(0, cvcLength) ?? '';
            handleChange(CreditCardFields.securityCode, newValue);
            if (newValue.length === cvcLength) holderField.current?.focus();
          }}
          value={props.securityCode.value}
          interactionLocked={true}
        />
      </FormLabel>
      <FormLabel style={{ marginTop: '40px' }}>
        Nome do titular
        <FormField
          showValidity="afterblur"
          onChange={(ev) =>
            handleChange(
              CreditCardFields.holderName,
              ev.currentTarget.value
                .match(/([a-z]| )+/gi)
                ?.join('')
                .toUpperCase() ?? '',
            )
          }
          isValid={props.holderName.isValid}
          autoComplete="cc-name"
          ref={holderField}
          value={props.holderName.value}
          placeholder="Como impresso no cartão"
        />
      </FormLabel>
      <FormLabel style={{ marginTop: '40px' }}>
        Validade
        <FormField
          showValidity="afterblur"
          onChange={(ev) => {
            const { value } = ev.currentTarget;
            let newValue = value.match(/[0-9]+/gi)?.join('') ?? '';
            if (newValue.length === 1 && Number(newValue[0]) > 1) {
              newValue = `0${newValue}/`;
            } else if (newValue.length > 2) {
              newValue = `${newValue.substr(0, 2)}/${newValue.substr(2, 4)}`;
            }
            handleChange(CreditCardFields.expiryDate, newValue);
          }}
          isValid={props.expiryDate.isValid}
          value={props.expiryDate.value}
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="MM/AAAA"
          autoComplete="cc-exp"
          interactionLocked={true}
        />
      </FormLabel>
      {installmentOptions && (
        <FormLabel style={{ marginTop: '40px' }}>
          Parcelamento
          <FormSelect
            value={props.installments.value}
            onChange={(ev) =>
              handleChange(CreditCardFields.installments, ev.currentTarget.value)
            }
            options={installmentOptions!.map((option) => ({
              value: option.installments.toString(),
              name: option.noInterest
                ? `${option.installments}x sem juros de R$ ${option.installmentAmount
                    .toFixed(2)
                    .replace('.', ',')}`
                : `${option.installments}x com juros de R$ ${option.installmentAmount
                    .toFixed(2)
                    .replace('.', ',')}`,
            }))}
          />
        </FormLabel>
      )}
    </>
  );
};

export default CreditCardStep1;

import {
  Installment,
  IdDocument,
  BuyerFields,
  CreditCardFields,
  BoletoFields,
} from '../../types';
import MercadoPagoGateway from './MercadoPago';

export type CardDetails = {
  methodId: string;
  typeId: string;
  issuerId: string;
  issuerName: string;
  issuerLogoUrl: string;
  installments: Installment[];
};

type DefaultCheckoutParams = 'amount';

type CreditCheckoutParams = {
  [Key in (BuyerFields | CreditCardFields) | DefaultCheckoutParams]: string;
} & {
  checkoutType: 'credit';
  paymentMethodId: string;
};

type BoletoCheckoutParams = {
  [Key in (BuyerFields | BoletoFields) | DefaultCheckoutParams]: string;
} & {
  checkoutType: 'boleto';
};

export default interface Gateway {
  getCardDetails(number: string, amount: number): Promise<CardDetails>;
  setup(): Promise<void>;
  getIdentificationTypes(): IdDocument[] | undefined;
  validateField(
    key: BuyerFields | CreditCardFields | BoletoFields,
    value: string,
  ): boolean;
  checkout(data: CreditCheckoutParams | BoletoCheckoutParams): Promise<string | undefined>;
}

export const gateway = MercadoPagoGateway();

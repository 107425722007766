import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Branch = styled.img`
  width: 53%;
  position: relative;
  left: -3%;
`;

export const NamesContainer = styled.div`
  margin: 2.6% auto 0;
  width: 94%;
  text-align: center;
`;

import { FC, useEffect, useRef } from 'react';
import FormField from '../../components/FormField';
import { FormLabel } from '../../components/FormLabel';
import { CreditCardFields } from '../../types';

interface Props {
  handleChange(key: CreditCardFields, value: string): void;
}

type FieldValues = {
  [Key in CreditCardFields]: { value: string; isValid: boolean };
};

const formatMoney = (value: string) => 'R$ ' + value.replace('.', ',');

export const AmountStep: FC<Props & FieldValues> = (props) => {
  const { handleChange } = props;
  const amountField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    amountField.current?.focus();
  }, []);

  const handleAmountChange = (value: string) => {
    const newValue = (
      Number(
        value
          .match(/[0-9]+/gi)
          ?.join('')
          ?.replace(/^0+/g, '') || '0',
      ) / 100 || 0
    ).toFixed(2);

    handleChange(CreditCardFields.amount, newValue);
  };

  return (
    <>
      <FormLabel style={{ position: 'relative' }}>
        Valor do presente
        <FormField
          showValidity="afterblur"
          ref={amountField}
          isValid={props.amount.isValid}
          onBlur={(ev) => handleAmountChange(ev.currentTarget.value)}
          onChange={(ev) => handleAmountChange(ev.currentTarget.value)}
          autoComplete="none"
          pattern="[0-9]*"
          inputMode="numeric"
          value={formatMoney(props.amount.value)}
          placeholder="00,00"
          interactionLocked={true}
        />
        <span
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </FormLabel>
      <FormLabel style={{ marginTop: '40px' }}>
        Mensagem (opcional)
        <FormField
          showValidity="afterblur"
          isValid={true}
          onChange={(ev) =>
            handleChange(CreditCardFields.message, ev.currentTarget.value)
          }
          autoComplete="none"
          value={props.message.value}
          placeholder="Um recadinho pra ir junto com o presente 😊"
        />
      </FormLabel>
    </>
  );
};

import styled from 'styled-components';
import LogoMercadoPago from '../../assets/img/mercado-pago-logo.png';

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  max-width: calc(100vw - 60px);
  margin: 0 auto;
  padding: 0 30px;
  font-size: 14px;
  flex-wrap: wrap;

  & > * {
    margin-top: 30px;
  }
`;

export const AppFooter = () => {
  return (
    <Footer>
      <a
        style={{ flexShrink: 0, marginRight: '20px' }}
        href="https://wa.me/5541997796896"
        title="Conversar com os noivos"
      >
        Algum problema ou dúvida?
      </a>
      <div
        style={{
          textAlign: 'right',
          lineHeight: 1.6,
        }}
      >
        Tecnologia do{' '}
        <img
          style={{ verticalAlign: 'middle', marginLeft: '10px' }}
          width={100}
          alt="Mercado Pago"
          src={LogoMercadoPago}
        />
      </div>
    </Footer>
  );
};

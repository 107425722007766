import { useEffect, useState } from 'react';

export enum Pages {
  RSVP = 'rsvp',
  GIFTS = 'presentes',
  NOT_FOUND = 'not_found',
  THANK_YOU = 'obrigado',
}

const pageTitleMap: Record<Pages, string> = {
  [Pages.RSVP]: 'Confirmação de presença',
  [Pages.GIFTS]: 'Lista de presentes',
  [Pages.NOT_FOUND]: 'Página não encontrada',
  [Pages.THANK_YOU]: 'Muito obrigado!',
};

const getHash = () => {
  const hash = window.location.hash.replace('#', '');

  if (hash === '') return Pages.RSVP;

  if (Object.values(Pages).includes(hash as any)) return hash as Pages;

  return Pages.NOT_FOUND;
};

export const useRouter = () => {
  const [page, setPage] = useState<Pages>(getHash());

  useEffect(() => {
    window.document.title = `${pageTitleMap[page]} | Karla & Eldade`;
  }, [page]);

  useEffect(() => {
    const handler = () => setPage(getHash());

    window.addEventListener('hashchange', handler);

    return () => window.removeEventListener('hashchange', handler);
  }, []);

  return {
    page,
  };
};

import { FC } from 'react';
import PaymentFormContainer from '../payment/paymentFormContainer';
import { AppFooter } from '../../components/AppFooter';
import { Main } from '../rsvp/styles';

export const GiftsPage: FC = () => {
  return (
    <>
      <Main>
        <h2>🎁 ❤️ Presentes</h2>
        <p>
          Optamos por não fazer uma lista de presentes convencional porque, depois do
          casamento, vamos passar alguns meses viajando e não sabemos quando teremos uma
          residência fixa.
        </p>
        <p>Assim, preferimos receber os presentes em cash, verdinhas, PIX, etc. 💰💸🤓</p>
      </Main>
      <PaymentFormContainer
        allowBankSlip={true}
        creditCardThankYouPage="/#obrigado"
        boletoThankYouPage="/#obrigado"
        defaultInstallments={1}
      />
      <AppFooter />
    </>
  );
};

import {
  useState,
  SyntheticEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
} from 'react';

interface Props {
  interactionLocked?: boolean;
  isValid?: boolean;
  showValidity?: 'afterblur' | 'always';
}

type ExtendedProps = Props &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const lockField = (shouldLock?: boolean) => {
  if (shouldLock) {
    return (ev: SyntheticEvent) => ev.preventDefault();
  }
};

const FormField = forwardRef<HTMLInputElement, ExtendedProps>((props, ref) => {
  const { interactionLocked, isValid, showValidity, ...defaultProps } = props;
  const [validity, setValidity] = useState(showValidity === 'always');
  return (
    <input
      ref={ref}
      onSelect={lockField(interactionLocked)}
      onCopy={lockField(interactionLocked)}
      onCut={lockField(interactionLocked)}
      onDrop={lockField(interactionLocked)}
      onDrag={lockField(interactionLocked)}
      {...defaultProps}
      style={{
        borderColor: isValid === false && validity ? '#e18b8b' : '',
      }}
      onBlur={(ev) => {
        defaultProps.onBlur && defaultProps.onBlur(ev);
        if (showValidity === 'afterblur' && !validity) setValidity(true);
      }}
    />
  );
});

export default FormField;

import styled from 'styled-components';

export const Header = styled.header`
  margin: 50px 0;

  & .dates {
    margin: 0 auto;
    width: 94%;
    text-align: right;
    font-size: ${({ theme }) => theme.textSizePx}px;
  }
`;

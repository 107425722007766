import { FC, useEffect, useState } from 'react';
import { PaymentMethod, CreditCardFields, BuyerFields, BoletoFields } from '../../types';
import { gateway, CardDetails } from '../../services/gateway';
import PaymentForm from '.';

type FormData = {
  [Key in CreditCardFields | BuyerFields | BoletoFields]: {
    value: string;
    isValid: boolean;
  };
};

type Props = {
  creditCardThankYouPage: string;
  boletoThankYouPage: string;
  defaultInstallments?: number;
  allowBankSlip: boolean;
};

const PaymentFormContainer: FC<Props> = (props) => {
  const [gatewayReady, setGatewayReady] = useState(false);
  const [cardDetails, setCardDetails] = useState<CardDetails | undefined>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | undefined
  >(props.allowBankSlip ? undefined : 'credit');

  const [formData, setFormData] = useState<FormData>({
    cardNumber: { value: '', isValid: false },
    expiryDate: { value: '', isValid: false },
    holderName: { value: '', isValid: false },
    installments: { value: '', isValid: false },
    securityCode: { value: '', isValid: false },
    email: { value: '', isValid: false },
    documentType: { value: '', isValid: false },
    documentNumber: { value: '', isValid: false },
    firstName: { value: '', isValid: false },
    lastName: { value: '', isValid: false },
    amount: { value: '0.00', isValid: false },
    message: { value: '', isValid: true },
  });

  useEffect(() => {
    gateway
      .setup()
      .then(() => {
        setGatewayReady(true);
        const [idType] = gateway.getIdentificationTypes() ?? [];
        setFormData((prev) => ({
          ...prev,
          documentType: {
            value: idType?.id ?? '',
            isValid: true,
          },
        }));
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <PaymentForm
      goBack={() => setSelectedPaymentMethod(undefined)}
      handleChange={(key, value) => {
        setFormData((prev) => {
          if (
            key === CreditCardFields.cardNumber &&
            value.length >= 6 &&
            prev.cardNumber.value.length < 6
          ) {
            setCardDetails(undefined);
            gateway
              .getCardDetails(value, Number(formData.amount.value))
              .then(setCardDetails)
              .catch(console.error);
            return {
              ...prev,
              cardNumber: {
                value,
                isValid: false,
              },
              installments: {
                value: props.defaultInstallments?.toString() ?? '1',
                isValid: true,
              },
            };
          } else {
            return {
              ...prev,
              [key]: {
                value: value.trimStart(),
                isValid: gateway.validateField(key, value.trimStart()),
              },
            };
          }
        });
      }}
      checkout={() => {
        setGatewayReady(false);
        setSelectedPaymentMethod(undefined);

        if (selectedPaymentMethod === 'credit') {
          (window as any).name = formData.holderName.value.split(' ')[0];
        } else {
          (window as any).name = formData.firstName.value;
        }

        gateway
          .checkout(
            selectedPaymentMethod === 'credit'
              ? {
                  cardNumber: formData.cardNumber.value,
                  documentNumber: formData.documentNumber.value,
                  documentType: formData.documentType.value,
                  email: formData.email.value,
                  expiryDate: formData.expiryDate.value,
                  holderName: formData.holderName.value,
                  installments: formData.installments.value,
                  securityCode: formData.securityCode.value,
                  amount: formData.amount.value,
                  paymentMethodId: cardDetails?.methodId ?? '',
                  checkoutType: 'credit',
                  message: formData.message.value,
                }
              : {
                  amount: formData.amount.value,
                  documentNumber: formData.documentNumber.value,
                  documentType: formData.documentType.value,
                  email: formData.email.value,
                  firstName: formData.firstName.value,
                  lastName: formData.lastName.value,
                  checkoutType: 'boleto',
                  message: formData.message.value,
                },
          )
          .then((boletoUrl) => {
            if (boletoUrl) {
              window.location.href = props.boletoThankYouPage;
              (window as any).boletoUrl = boletoUrl;
            } else {
              (window as any).boletoUrl = undefined;
              window.location.href = props.creditCardThankYouPage;
            }
          })
          .catch((err) => {
            console.error(err);
            alert('Pagamento recusado :(\nPor favor, verifique os dados inseridos.');
          })
          .finally(() => setGatewayReady(true));
      }}
      installmentOptions={cardDetails?.installments}
      formData={formData}
      selectedPaymentMethod={selectedPaymentMethod}
      selectPaymentMethod={setSelectedPaymentMethod}
      documentTypes={gateway.getIdentificationTypes()}
      loading={!gatewayReady}
      cardIssuer={cardDetails?.methodId}
    />
  );
};

export default PaymentFormContainer;

import styled from 'styled-components';
import { useRouter, Pages } from '../../router';
import { Wrapper } from '../Wrapper';

const NavItem = styled.a<{ active: boolean }>`
  display: block;
  background: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.textColorContrast};
  padding: 17px 20px;
  text-transform: uppercase;
  cursor: pointer;
  opacity: ${({ active }) => (active ? 0.7 : 1)};
  & > * {
    opacity: ${({ active }) => (active ? 0.7 : 1)};
  }
`;

const Container = styled.nav`
  & ${NavItem} + ${NavItem} {
    margin-top: 1px;
  }
`;

export const Nav = () => {
  const { page } = useRouter();

  return (
    <Container>
      <NavItem active={page === Pages.RSVP} href="#">
        <Wrapper>Confirmação de presença</Wrapper>
      </NavItem>
      <NavItem active={page === Pages.GIFTS} href="#presentes">
        <Wrapper>Lista de presentes</Wrapper>
      </NavItem>
    </Container>
  );
};

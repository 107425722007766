import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Wrapper } from '../../components/Wrapper';
import { PaymentMethod } from '../../types';

const List = styled.ul`
  list-style: none;

  li {
    margin-top: 25px;
  }
`;

const Container = styled.div`
  padding: 50px 20px;
  & > b {
    font-weight: 700;
  }
`;

const Button = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 18px 14px;
    width: 100%;
    border: none;
    cursor: pointer;
    font: inherit;
    border-radius: 4px;
    background: #fff;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);

    &:hover {
      background: #eee;
      color: ${theme.primaryColor};
      svg {
        fill: ${theme.primaryColor};
      }
    }

    span {
      flex: 1;
      padding-left: 18px;
      text-align: left;
      line-height: 1.2;
      display: block;

      i {
        font-size: 12px;
        display: block;
      }
    }
  `}
`;

interface Props {
  selectMethod(method: PaymentMethod): void;
}

const ChoosePaymentMethod: FC<Props> = ({ selectMethod }) => {
  return (
    <Wrapper>
      <Container>
        <b>Escolha como presentear</b>
        <List>
          <li>
            <Button
              onClick={(ev) => {
                ev.preventDefault();
                selectMethod('credit');
              }}
            >
              <svg
                fill="#aaa"
                width="52px"
                viewBox="0 0 512 320"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M480 0H32C14.312 0 0 14.313 0 32V96H512V32C512 14.313 497.688 0 480 0Z" />
                <path d="M0 160V288C0 305.688 14.313 320 32 320H480C497.688 320 512 305.687 512 288V160H0ZM160 256H64V192H160V256ZM320 256H288V224H320V256ZM384 256H352V224H384V256ZM448 256H416V224H448V256Z" />
              </svg>
              <span>
                Cartão de crédito
                <i>até 10x s/ juros</i>
              </span>
            </Button>
          </li>
          <li>
            <Button
              onClick={(ev) => {
                ev.preventDefault();
                selectMethod('boleto');
              }}
            >
              <svg fill="#aaa" width="52px" viewBox="0 0 512 512">
                <path d="M0 448V64h18v384H0zm26.857-.273V64H36v383.727h-9.143zm27.143 0V64h8.857v383.727H54zm44.857 0V64h8.857v383.727h-8.857zm36 0V64h17.714v383.727h-17.714zm44.857 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm35.715 0V64h18v383.727h-18zm44.857 0V64h18v383.727h-18zm35.999 0V64h18.001v383.727h-18.001zm36.001 0V64h18.001v383.727h-18.001zm26.857 0V64h18v383.727h-18zm45.143 0V64h26.857v383.727h-26.857zm35.714 0V64h9.143v383.727H476zm18 .273V64h18v384h-18z" />
              </svg>
              <span>Boleto</span>
            </Button>
          </li>
          <li>
            <Button
              onClick={(ev) => {
                ev.preventDefault();
                selectMethod('pix');
              }}
            >
              <svg
                fill="#aaa"
                width="52"
                viewBox="0 0 52 52"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M40.5529 39.7796C38.5129 39.7796 36.594 38.9853 35.1513 37.5433L27.3519 29.7437C26.8043 29.1946 25.8499 29.1963 25.3025 29.7437L17.4746 37.5718C16.0319 39.0138 14.1131 39.8081 12.073 39.8081H10.5359L20.4142 49.6862C23.4991 52.7711 28.501 52.7711 31.5861 49.6862L41.4924 39.7796H40.5529Z" />
                <path d="M12.0729 12.1916C14.113 12.1916 16.0318 12.986 17.4745 14.4279L25.3024 22.2572C25.8662 22.8212 26.7867 22.8233 27.3518 22.2566L35.1512 14.4565C36.5939 13.0145 38.5127 12.2201 40.5528 12.2201H41.4922L31.5862 2.31385C28.5008 -0.771283 23.499 -0.771283 20.4141 2.31385L10.5363 12.1916L12.0729 12.1916Z" />
                <path d="M49.6863 20.414L43.6999 14.4276C43.5681 14.4804 43.4254 14.5133 43.2747 14.5133H40.5529C39.1457 14.5133 37.7682 15.0841 36.7738 16.0791L28.9747 23.8785C28.2448 24.6084 27.2858 24.9736 26.3276 24.9736C25.3686 24.9736 24.4102 24.6084 23.6806 23.8792L15.8519 16.0506C14.8576 15.0554 13.4801 14.4848 12.0729 14.4848H8.72614C8.58338 14.4848 8.44999 14.4512 8.32406 14.4038L2.31385 20.414C-0.771283 23.4991 -0.771283 28.5007 2.31385 31.5859L8.32382 37.5959C8.44998 37.5484 8.58338 37.5148 8.72614 37.5148H12.0729C13.4801 37.5148 14.8576 36.9443 15.8519 35.9492L23.6798 28.1213C25.0947 26.7076 27.5612 26.7071 28.9747 28.122L36.7738 35.9207C37.7682 36.9157 39.1457 37.4865 40.5529 37.4865H43.2747C43.4254 37.4865 43.5681 37.5194 43.6999 37.5722L49.6863 31.5858C52.7712 28.5007 52.7712 23.4991 49.6863 20.414Z" />
              </svg>
              <span>PIX, depósito ou transferência</span>
            </Button>
          </li>
        </List>
      </Container>
    </Wrapper>
  );
};

export default ChoosePaymentMethod;
